import * as React from 'react'
import Footer from '../../../../components/Footer/Footer'
import Bounce from 'react-reveal/Bounce'
import Gallery, { useGallery } from '../../../../components/Gallery/Gallery'
import images from '../../../../components/Gallery/ImageLists/tusk-2019/quiz-london'

import './../tusk-2019.scss'

const QuizNight = () => {
  const { imageSelected, isOpen, onImageClicked, setIsOpen } = useGallery()

  return (
    <>
      <div id="quiz-london">
        <section id="hero" className="hero standard wrap">
          <nav className="wrap nav">
            <div className="container cf">
              <a href="https://www.emso.com/" title="Back to Emso" id="logo">
                <img src="/images/tusk-2018/images/emso-logo.svg" alt="Emso logo" width="90" height="62" />
              </a>
            </div>
          </nav>
          <div className="page-title">
            <div className="container cf">
              <div className="row">
                <div className="col-base-12 col-xs-9 col-sm-7 col-lg-8">
                  <h1>Emso Quiz Night London 2019</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <main id="content" className="wrap" role="main">
          <Gallery images={images} imageSelected={imageSelected} isOpen={isOpen} setIsOpen={setIsOpen} />
          <Bounce bottom>
            <section id="safaricom-galery" className="section-padding">
              <div className="container">
                <div className="row">
                  <div className="col-base-12 col-xs-9 col-sm-7 col-lg-8">
                    <h6>Photo gallery</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7524-2.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7524-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7589-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7589-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7590-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7590-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7591-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7591-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7596-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7596-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7597-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7597-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7599-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7599-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7600-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7600-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7602-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7602-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7605-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7605-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7607-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7607-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7609-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7609-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7610-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7610-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7611-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7611-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7613-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7613-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7620-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7620-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7621-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7621-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7623-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7623-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7627-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7627-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7630-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7630-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7631-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7631-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7632-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7632-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7633-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7633-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7634-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7634-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7636-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7636-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7640-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7640-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7641-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7641-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7643-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7643-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7644-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7644-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7645-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7645-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7646-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7646-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7649-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7649-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7650-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7650-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7651-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7651-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7652-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7652-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7653-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7653-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7654-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7654-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7655-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7655-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7656-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7656-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7663-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7663-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7664-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7664-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7665-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7665-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/5DDD7666-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/5DDD7666-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_0.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_0.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_1.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_1.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_10.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_10.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_11.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_11.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_12.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_12.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_13.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_13.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_14.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_14.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_15.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_15.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_16.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_16.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_17.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_17.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_18.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_18.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_19.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_19.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_20.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_20.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_3.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_3.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_4.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_4.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_6.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_6.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_7.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_7.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_8.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_8.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/gif_9.gif')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/gif_9.gif" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7417-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7417-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7426-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7426-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7427-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7427-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7430-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7430-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7434-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7434-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7443-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7443-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7445-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7445-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7446-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7446-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7449-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7449-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7455-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7455-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7457-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7457-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7461-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7461-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7466-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7466-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7467-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7467-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7473-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7473-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7477-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7477-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7478-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7478-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7479-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7479-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7480-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7480-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7488-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7488-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7492-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7492-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7493-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7493-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7496-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7496-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7505-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7505-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7508-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7508-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7510-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7510-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7513-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7513-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7515-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7515-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7522-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7522-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7527-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7527-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7528-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7528-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7535-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7535-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7538-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7538-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/large/IMG_7541-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7541-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7545-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7545-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7547-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7547-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7559-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7559-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7561-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7561-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7562-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7562-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7565-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7565-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7567-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7567-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7571-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7571-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7573-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7573-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7581-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7581-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7582-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7582-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7604-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7604-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7606-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7606-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7609-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7609-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7610-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7610-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7623-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7623-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7631-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7631-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7632-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7632-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7633-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7633-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7634-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7634-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7636-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7636-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7637-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7637-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7638-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7638-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7645-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7645-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7648-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7648-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7663-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7663-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7671-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7671-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7678-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7678-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7683-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7683-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7693-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7693-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7695-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7695-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7705-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7705-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7706-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7706-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7709-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7709-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7712-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7712-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7731-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7731-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7742-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7742-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7750-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7750-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7751-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7751-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7756-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7756-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7759-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7759-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7760-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7760-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7768-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7768-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7773-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7773-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7776-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7776-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7780-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7780-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7781-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7781-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7791-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7791-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7794-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7794-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7817-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7817-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7821-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7821-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7828-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7828-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7834-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7834-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7836-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7836-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7841-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7841-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7843-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7843-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7849-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7849-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7851-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7851-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7856-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7856-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7883-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7883-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7897-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7897-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7902-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7902-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7909-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7909-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7913-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7913-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7921-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7921-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7925-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7925-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7933-2 (1).jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7933-2 (1).jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7933-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7933-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7937-2 (1).jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7937-2 (1).jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7937-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7937-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7939-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7939-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_7942-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_7942-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9522-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9522-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9527-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9527-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9529-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9529-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9530-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9530-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9531-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9531-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9532-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9532-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9533-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9533-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9535-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9535-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9536-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9536-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9537-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9537-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9538-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9538-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9540-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9540-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9541-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9541-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9543-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9543-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9544-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9544-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9548-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9548-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9549-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9549-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9550-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9550-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9551-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9551-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/IMG_9556-2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/IMG_9556-2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_10.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_10.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_11.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_11.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_13.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_13.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_14.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_14.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_15.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_15.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_16.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_16.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_17.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_17.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_18.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_18.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_19.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_19.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_20.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_20.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_21.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_21.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_22.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_22.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_23.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_23.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_24.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_24.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_25.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_25.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_26.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_26.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_27.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_27.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_28.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_28.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_29.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_29.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_3.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_3.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_30.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_30.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_31.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_31.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_32.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_32.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_33.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_33.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_34.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_34.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_35 (1).jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_35 (1).jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_35.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_35.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_36.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_36.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_37.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_37.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_38.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_38.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_39.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_39.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_4.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_4.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_40.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_40.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_41.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_41.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_42.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_42.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_43.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_43.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_44.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_44.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_45.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_45.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_46.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_46.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_5.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_5.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_6.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_6.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_7.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_7.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_8.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_8.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/photo_9.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/photo_9.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/Thumbnail 2.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/Thumbnail 2.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/thumbnail 3.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/thumbnail 3.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/quiznight/london/large/thumnail 1.jpg')
                      }}
                    >
                        <img src="/images/tusk-2019/images/quiznight/london/small/thumnail 1.jpg" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Bounce>
        </main>
      </div>
      <Footer />
    </>
  )
}

export default QuizNight
